$(document).ready(function() {
    $('#teaser').vc_carousel({
        fx:				'fade',
        duration:		1500,
        autoSpeed:		8000,
        paginate:		false,
        showNavArrows:	false,
        preloader:		$('.vc_carousel_preloader')
    });
});
lightbox.option({
    albumLabel: 'Bild %1 von %2'
});