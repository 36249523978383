// JavaScript Document
/*************************************************
**  jQuery Carousel version 0.1.6
**  Copyright Michael Lehmann, licensed GNU GPLv3
**  micha.ist.nr1@googlemail.com
**  http://www.lohmann-robinski.de
**************************************************/
(function($){
	$.vc_carousel = function(opts, el){
		this.el = $(el);
		this._init(opts);
	};
	
	$.vc_carousel.settings = {
		wrapperElement:			"vc_carousel",
		overlay:				null,
		pagesWrap:				"<div class=\"vc_carousel_pages\" />",
		pagesOwnElems:			null,
		paginate:				true,
		showNavArrows:			true,
		arrowSwitchDuration:	'fast',
		easing:					'swing',
		duration:				'slow',
		itemSel:				'ul > li',
		itemTitle:				'.vc_carousel_title',
		auto:					true,
		autoDirection:			'next',
		autoSpeed:				5000,
		timer:					null,
		fx:						'slide',
		preloader:				null,
		resizeWrap:				false
	};
	
	$.vc_carousel.prototype = {
		_init : function(opts) {
			var self = this;
			this.opts = $.extend(true, {}, $.vc_carousel.settings, opts);
			$(self.el).addClass(this.opts.wrapperElement);

			self.currentShowing = 0;
			this.items = self.el.find(this.opts.itemSel);
			this.items.not(':eq('+self.currentShowing+')').hide();
			this.items.find(this.opts.itemTitle).wrapInner('<div class="vc_carousel_title_inner"><div></div></div>').hide();
			
			$(window).resize(function() {
				self.w = self.items.eq(0).width();
			}).trigger('resize');
						
			if (this.opts.overlay!=null && this.opts.overlay.length)
				this.opts.overlay.insertAfter(self.el);
				
			if (this.opts.preloader!=null) {
				var callback = null;
				if (this.opts.auto==true && this.items.length>1) {
					callback = this.play();
				}
				self.preloadImages(this.items, this.opts.preloader, callback);
			}
			
			if (this.items.length>1) {
				if (this.opts.paginate) {
					if (this.opts.pagesOwnElems!=null && this.opts.pagesOwnElems.length!=0) {
						self.pager = this.opts.pagesOwnElems;
					} else {
						self.pager = $(this.opts.pagesWrap).appendTo(self.el);
						this.items.each( function(i) {
							var elem = this;
							$('<button class="vc_carousel_sprite vc_carousel_button vc_carousel_page"><span>'+(i+1)+'</span></button>')
							.appendTo(self.pager)
							.bind('click', function() {
								if(self.opts.auto==true) {
									self.stop();
								}
								self._show(elem);
							});
						});
					}
				}
				
				if ($.isFunction($.fn.touchwipe)) {
					self.el.touchwipe({
						wipeLeft: function() {
							self.next();
						},
						wipeRight: function() {
							self.prev();
						},
						min_move_x: 20,
						preventDefaultEvents: true
					});
				}
				
				if (this.opts.showNavArrows) {
					$('<button class="vc_carousel_sprite vc_carousel_button vc_carousel_prev" title="previous"><span>&lt;</span></button>')
					.appendTo(self.el)
					.bind('click', function() {
						self.prev(self.opts.arrowSwitchDuration);
					});
					
					$('<button class="vc_carousel_sprite vc_carousel_button vc_carousel_next" title="next"><span>&gt;</span></button>')
					.appendTo(self.el)
					.bind('click', function() {
						self.next(self.opts.arrowSwitchDuration);
					});
				}
				
				if(this.opts.auto==true && this.opts.preloader==null) {
					
				}
			}
			this._afterAnimate();
		},

		_next : function() {
			var self = this;
			var next = ((self.currentShowing+1) < self.items.length) ? self.currentShowing+1 : 0;
			self._show(self.items.eq(next));
		},
		
		_prev : function() {
			var self = this;
			var prev = ((self.currentShowing-1)>-1) ? self.currentShowing-1 : (self.items.length-1);
			self._show(self.items.eq(prev));
		},

		_show : function(el) {
			var self = this;
			if (self.items.filter(":animated").length==0) {				
				var next = self.items.index($(el));
				if (next!=self.currentShowing && next!=-1) {
					self._beforeAnimate();
					
					switch (self.opts.fx) {
						case 'slide':
							if (self.opts.autoDirection=='next') {
								var curEl = "-"+self.w+"px";
								var nextEl = self.w+"px";
							} else {
								var curEl = "+"+self.w+"px";
								var nextEl = "-"+self.w+"px";
							}
							self.items.eq(self.currentShowing).animate({"left": curEl}, self.opts.duration, self.opts.easing, function() {
								$(this).css({"z-index":"0"}).hide();
							});
							self.items.eq(next).css({"z-index":"1", "left": nextEl}).show().animate({"left": "0px"}, self.opts.duration, self.opts.easing, function() {
								self.currentShowing = next;
								self._afterAnimate();
							});
						break;

						default:
							self.items.eq(self.currentShowing).css({"z-index":"1", "left":"0px"}).animate({"opacity": "0"}, self.opts.duration, self.opts.easing, function() {
								$(this).css({"z-index":"0"}).hide();
							});
							self.items.eq(next).css({"z-index":"0", "left":"0px"}).show().animate({"opacity": "1"}, self.opts.duration, self.opts.easing, function() {
								$(this).css({"z-index":"1"});
								self.currentShowing = next;
								self._afterAnimate();
							});
						break;
					}
				}
			}
			return;
		},
		
		_beforeAnimate : function() {
			var title = this.el.children(this.opts.itemTitle);
			if (title.length!=0) {
				title
				.children('.vc_carousel_title_inner')
				.animate({
						left	: '-'+title.outerWidth()+'px',
						opacity	: 0
					}, 200, 'swing', function() {
						title.remove();
					}
				);
			}
			if (this.pager)
				this.pager.children().removeClass('aktiv');
		},
		
		_afterAnimate : function() {
			var self = this;
			
			var title = this.items.eq(this.currentShowing).find(this.opts.itemTitle).clone();
			if (title.length!=0) {
				title.appendTo(this.el).show();
				var titleInner = title.children('.vc_carousel_title_inner').hide();
				
				var titleTrigger = $('<div class="vc_carousel_sprite vc_carousel_title_trigger"></div>').prependTo(title).click(function() {
					$(this).toggleClass('btnActive');
					titleInner.toggle('fast');
					if ($(this).hasClass('btnActive')) {
						self.stop();
					} else {
						self.play();
					}
				});
				titleTrigger.css({right: '-'+titleTrigger.outerWidth()+'px', opacity: 0}).animate({
					right	: 0,
					opacity	: 1
				}, 400, 'swing');
			}
			
			if (this.opts.resizeWrap) {
				var newHeight = this.items.eq(this.currentShowing).height();
				this.el.animate({height: newHeight+'px'});
			}
			if (this.pager) {
				this.pager.children().eq(this.currentShowing).addClass('aktiv');
			}
			
		},
		/* Public methods */	
		play : function() {
			var self = this;
			self.opts.timer = setInterval(function() {
				if (self.opts.autoDirection=='next') {	
					self._next();
				} else {
					self._prev();
				}
			}, self.opts.autoSpeed);
		},
		
		stop : function() {
			var self = this;
			clearInterval(self.opts.timer);
		},
		
		display : function(i) {
			var self = this;
			if (self.items.eq(i).length!=0) {
				self.stop();
				self._show(self.items.eq(i));
			}
		},
		
		prev : function(speed) {
			var self = this;
			var tmp = self.opts.duration;
			self.opts.duration = (speed) ? speed : tmp;
			self.opts.autoDirection='prev';
			self.stop();
			self._prev();
			self.play();
			self.opts.duration = tmp; 
		},
		
		next : function(speed) {
			var self = this;
			var tmp = self.opts.duration;
			self.opts.duration = (speed) ? speed : tmp;
			self.opts.autoDirection='next';
			self.stop();
			self._next();
			self.play();
			self.opts.duration = tmp; 
		},
		
		preloadImages : function(el, preloader, callback) {
			var l = $(el).length;
			$(el).each( function() {
				var image = new Image();
				 $(image).bind('load error', function (event) {
					l--;
					if (l==0) {
						$(preloader).fadeOut( function() {
							$(this).remove();
							if(callback != undefined && typeof callback == 'function') callback();
						});
					}
				});
				if ($(this).is('[src]')) {
					image.src = $(this).attr('src');
				} else if ($(this).find('img:eq(0)[src]').length!=0) {
					image.src = $(this).find('img:eq(0)').attr('src');
				} else {
					var pattern = /url\(|\)|"|'/g;
					image.src = $(this).css('backgroundImage').replace(pattern,"");
				}
			});
		}
	};
	
	var logError = function(message) {
		if (this.console) {
			console.error(message);
		}
	};
	
	$.fn.vc_carousel = function(opts) {
		if (typeof opts==='string') {
			// call method
			var args = Array.prototype.slice.call(arguments, 1);
			
			this.each(function(){
				var instance = $.data(this, 'vc_carousel');
				if (!instance) {
					logError("cannot call methods on carousel prior to initialization; attempted to call method '"+opts+"'");
					return;
				}
				if (!$.isFunction(instance[opts]) || opts.charAt(0)=== "_") {
					logError("no such method '"+opts+"' for carousel instance");
					return;
				}
				// apply method
				instance[opts].apply(instance, args);
			});
		} else {
			this.each(function() {
				var instance = $.data(this, 'vc_carousel');
				if (instance) {
					instance.option(opts || {});
					instance._init(opts);
				} else {
					// initialize new instance
					$.data(this, 'vc_carousel', new $.vc_carousel(opts, this));
				}
			});
		}
		return this;
	};
})( jQuery );