$(document).ready(function() {
	//scrolling top bar
	var scroll = {
		timeout:	null,
		height:		0,
		breakpoint:	0,
		top:		0,
		el:			$('#topWrap').clone().addClass('fixed').insertAfter('#topWrap')
	};
	$(window).scroll( function() {
		scroll.height = $('#topWrap').height()+10;
		scroll.breakpoint = $('#teaserWrapMin').height()-scroll.height;
		scroll.top = $(this).scrollTop();
		
		if (scroll.top>scroll.breakpoint && scroll.el.css('margin-top')!='0px') {
			scroll.el.stop(true).show().animate({'margin-top':0},'fast','swing');
		} else if (scroll.top<=scroll.breakpoint && scroll.el.is(':not(:animated)')) {
			scroll.el.stop(true).animate({'margin-top':-scroll.height+'px'},'fast','swing', function() { $(this).hide(); });
		}
	}).trigger('scroll');
	
	$('.nav-trigger').each(function() {
		var self = $(this);
		var target = $(self.attr('data-target'));
		var timeout = null;
		if (target.length!=0) {
			if (target.hasClass(self.attr('data-toggle'))) {
				if (self.is('[data-switch]')) self.find('.sprt').toggleClass(self.attr('data-switch'));
				self.toggleClass('btnActive');
			}
			self.click(function() {
				clearTimeout(timeout);
				if (self.is('[data-switch]')) self.find('.sprt').toggleClass(self.attr('data-switch'));
				self.toggleClass('btnActive');
				target.slideToggle("fast", function() {
					$(this).toggleClass(self.attr('data-toggle'));
				});
				return false;
			});
			if (self.prev().is('a')) {
				self.prev().hover(function() {
					var trigger = $(this);
					if (!target.hasClass(self.attr('data-toggle'))) {
						clearTimeout(timeout);
						timeout = setTimeout(function() {
							self.trigger('click');
						}, 750);
					}
				}, function() {
					clearTimeout(timeout);
				});
			}
		}
	});

	afterAjaxLoad();
	
	if (window.location.hash!='') {
		var hash = '';
		hash = window.location.hash.replace(/#vid_/, "");
		if ($("#v_"+hash).length!=0) {
			$("#v_"+hash).trigger('click');
			return false;
		}
		
		hash = window.location.hash.replace(/#imgOpen_/, "");
		if ($("#img_"+hash).length!=0) {
			$("#img_"+hash).trigger('click');
			return false;
		}
	}
});

function effekt(id, wert) {
	if(wert==0 || wert=='nein' || wert=='no') $('#'+id).slideUp('slow');
	else $('#'+id).slideDown('slow');
}
function defaultText(elem, val1, val2) {
	if(elem.value.replace(/^\s+/,'').replace(/\s+$/,'')==val1) elem.value = val2;	
}

// forceNumeric() plug-in implementation
jQuery.fn.forceNumeric = function () {
	return $(this).each(function () {
		$(this).keydown(function (e) {
			var key = e.which || e.keyCode;
			if (!e.shiftKey && !e.altKey && !e.ctrlKey &&
				key >= 48 && key <= 57 ||
				key >= 96 && key <= 105 ||
				key == 190 || key == 188 || key == 109 || key == 110 ||
				key == 8 || key == 9 || key == 13 ||
				key == 35 || key == 36 ||
				key == 37 || key == 39 ||
				key == 46 || key == 45)
				return true;

			return false;
		});
	});
};

function afterAjaxLoad() {
	$('.numeric').forceNumeric();

	$(".vid").prettyPhoto({
		theme:			'facebook',
		social_tools:	false,
		default_width:	600
	});
	$(".img").prettyPhoto({
		theme:			'facebook',
		social_tools:	false,
		deeplinking:	false
	});
	$(".date .sprt").click( function() {
		if ($(this).prev().hasClass('hasDatepicker')) {
			$(this).prev('.hasDatepicker').datepicker('show');
		}
	});
	
	$(".frameMode").each( function() {
		var Ergebnis = this.href.search(/\?/);
		target = this.href+((Ergebnis!='-1')?'&':'?')+'frameMode=true&iframe=true';
		if ($(window).width()>479) {
			$(this).attr("href", target).prettyPhoto({
				theme:			'facebook',
				social_tools:	false,
				deeplinking:	false,
				default_width:	'95%',
				default_height:	'90%'
			});
		}
	});
}